import * as React from "react"
import * as Dialog from "../../lib/ui/dialog.tsx"

import { toSentenceCase } from "@iyk/string"
import { useLang } from "../lang/use-lang.ts"
import { AnimateHeightChange } from "./animate-height-change.tsx"
import { LoginForm } from "./login-form.tsx"

// #region LoginDialogProvider

export const LoginDialogProvider = ({ children }: { children: React.ReactNode }) => {
  const lang = useLang()
  const [isOpen, setIsOpen] = React.useState(false)
  const [showChooseWalletStep, setShowChooseWalletStep] = React.useState(false)
  const [onLoginComplete, setOnLoginComplete] = React.useState<
    (() => void) | undefined
  >()

  // Set options and show the login dialog
  const handleShowLoginDialog = React.useCallback(
    (options?: ShowLoginDialogOptions) => {
      const showChooseWalletStep = Boolean(options?.showChooseWalletStep)

      setShowChooseWalletStep(showChooseWalletStep)
      setOnLoginComplete(() => options?.onLoginComplete)
      setIsOpen(true)
    },
    [],
  )

  const handleLoginComplete = React.useCallback(() => {
    setIsOpen(false)
    onLoginComplete?.()
  }, [onLoginComplete])

  return (
    <LoginDialogContext.Provider
      value={{
        showLoginDialog: handleShowLoginDialog,
      }}
    >
      {children}
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Content onOpenAutoFocus={(e) => e.preventDefault()}>
          <Dialog.Title hidden>{toSentenceCase(lang.terms.LOGIN)}</Dialog.Title>
          <AnimateHeightChange className="p-0.5">
            <LoginForm
              showChooseWalletStep={showChooseWalletStep}
              onLoginComplete={handleLoginComplete}
            />
          </AnimateHeightChange>
        </Dialog.Content>
      </Dialog.Root>
    </LoginDialogContext.Provider>
  )
}

// #endregion

// #region LoginDialogContext

const LoginDialogContext = React.createContext<LoginDialogContextType | undefined>(
  undefined,
)

// #endregion

// #region useLoginDialog

export const useLoginDialog = () => {
  const context = React.useContext(LoginDialogContext)
  if (!context) {
    throw new Error("useLoginDialog must be used within a LoginDialogProvider")
  }
  return context
}

// #endregion

// #region Types

type ShowLoginDialogOptions = {
  showChooseWalletStep?: boolean
  onLoginComplete?: () => void
}

type LoginDialogContextType = {
  showLoginDialog: (options?: ShowLoginDialogOptions) => void
}

// #endregion
